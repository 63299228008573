import { map } from 'rxjs'
import { elementWaiter } from './elementWaiter'
import type { CodelessEvent } from './events'

interface NgineEdit {
  name: string
  selector: string
  type: 'text' | 'image'
  value: string
}

interface NgineTest {
  name: string
  id: string
  edits: NgineEdit[]
  control: boolean
  metric: number
  count: number
}

interface Ngine {
  sitePk: string
  ngineSk: string
  name: string
  pageUrl: string
  tests: NgineTest[]
  metricId: string
  metricType: 'event' | 'integration'
  status?: 'running' | 'stopped'
}

const IMAGE_UPLOAD_BUCKET_NAME = import.meta.env.IMAGE_UPLOAD_BUCKET_NAME || 'nlytics-dev-uploads-storage-bucket'

// Only count when the ngine's page is visited
export interface ActiveNgine { // stupid naming but idk what else to call this hahaha
  sessionId: string
  metricId: string
  metricType: 'event' | 'integration'
  ngineSk: string
  chosenTest: string
}

export const ngineGlobals: { activeNgines: ActiveNgine[] } = {
  activeNgines: [],
}

// This is set for more than A and B but usually we'll just have A and B
function chooseTest(window: Window, { tests }: Ngine) {
  // const visitorId = makeVisitorID()
  const test = tests[Math.floor(Math.random() * tests.length)]
  return test
}

function cookieSafeBtoa(s: string) {
  return btoa(s).replaceAll('=', '$')
}
function cookieSafeAtob(s: string) {
  return atob(s.replaceAll('$', '='))
}

// Initialize the ngine for the current session
export function initNgine(window: Window, sessionId: string, ngines: Ngine[]) {
  // const visitorId = makeVisitorID()

  // TODO: make this less WET
  // const savedActiveNgines = window.document.cookie.split('; ').filter(row => row.startsWith('ngine-st-')).map(row => row.split('=')).map(([key, base64]) => {
  const savedActiveNgines = Object.entries(localStorage).filter(([key]) => key.startsWith('ngine-st-')).map(([key, value]) => {
    const ngineSessionId = key.split('-')[2]
    const activeNgine: ActiveNgine = { sessionId: ngineSessionId, ...JSON.parse(value) }
    // if (metricType === 'event' && sessionId === ngineSessionId) { // todo: implement for visitors as well
    //   // codelessEventNgineTestMap[metricId] ??= new Set()
    //   // codelessEventNgineTestMap[metricId].add({ ngineSk, chosenTest })

    return activeNgine
  })

  // Loop over all the ngines that are not stopped and apply them
  ngineGlobals.activeNgines = savedActiveNgines

  const localStorageEntry = {}
  for (const ngine of ngines.filter(ngine => ngine.status !== 'stopped')) {
    const test = chooseTest(window, ngine)

    const { ngineSk, metricId, metricType } = ngine
    const chosenTest = { metricId, metricType, ngineSk, chosenTest: test.id, sessionId }
    // window.document.cookie = `ngine-st-${sessionId}-${cookieSafeBtoa(ngineSk)}=${btoa(JSON.stringify(chosenTest))};max-age=1200` // the ngine expires after this time
    localStorage.setItem(`ngine-st-${sessionId}-${ngineSk}`, JSON.stringify(chosenTest))

    ngineGlobals.activeNgines.push(chosenTest)
    // console.log('Chose test', test)

    if (test.control)
      continue
    for (const edit of test.edits) {
      elementWaiter.waitFor(edit.selector).subscribe(($element) => {
        // console.log('Found element', $element, 'for selector', edit.selector, 'and type', edit.type)
        switch (edit.type) {
          case 'text':
            $element.textContent = edit.value
            break
          case 'image': {
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('src', bucketUrl)
            break
          }
        }
      })
    }
  }
}

// This nasty little function is a transformer for codeless events that adds ngine information to them
export function createNgineMapper(window: Window, sessionId: string) {
  // Loop over every ngine cookie
  // const ngineCookies = window.document.cookie.split('; ').filter(row => row.startsWith('ngine-st-')).map(row => row.split('='))
  const codelessEventNgineTestMap: Record<string, Set<{ ngineSk: string; chosenTest: string }>> = {}
  // for (const [key, base64] of ngineCookies) {
  //   const ngineSessionId = key.split('-')[2]
  //   const { ngineSk, metricId, metricType, chosenTest } = JSON.parse(atob(base64))
  //   if (metricType === 'event' && sessionId === ngineSessionId) { // todo: implement for visitors as well. we only allow this within one session
  //     codelessEventNgineTestMap[metricId] ??= new Set()
  //     codelessEventNgineTestMap[metricId].add({ ngineSk, chosenTest })
  //   }
  // }
  for (const activeNgine of ngineGlobals.activeNgines) {
    const { ngineSk, metricId, metricType, chosenTest, sessionId: ngineSessionId } = activeNgine
    if (metricType === 'event' && sessionId === ngineSessionId) { // todo: implement for visitors as well. we only allow this within one session
      codelessEventNgineTestMap[metricId] ??= new Set()
      codelessEventNgineTestMap[metricId].add({ ngineSk, chosenTest })
    }
  }
  return map((event: CodelessEvent): CodelessEvent & { chosenTests?: { ngineSk: string; chosenTest: string }[] } => {
    const chosenTests = codelessEventNgineTestMap[event.eventListenerSk]
    if (chosenTests)
      return { ...event, chosenTests: [...chosenTests] }
    return event
  })
}
