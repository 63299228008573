export function proxyURL(url: string) {
	if (
		!/(?:https?:)?\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
			url,
		)
	)
		return url;
	if (url.startsWith("//")) url = "https:" + url; // TODO: make this dependent on whatever the current page protocol is
	if (url.includes("p.nlytics.co")) return url;
	// debugger;
	const proxy = new URL(url);
	proxy.host = proxy.protocol.slice(0, -1) + "--" + proxy.host.replaceAll(".", "-") + ".p.nlytics.co";
	proxy.protocol = "https:";
	return proxy.toString();
}
