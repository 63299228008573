// this is needed to make vite bundling work with a global
import './polyfills'

import { filter, fromEvent, merge, throttleTime } from 'rxjs'
import { Amplify } from 'aws-amplify'
import { flushEvents, record } from 'aws-amplify/analytics/kinesis'
import { createEverythingRecorderObservable, recordingSetup } from './recorder'
import type { ActionType, RecordingEventByActionType, RecordingSetup } from './types/recorder'
import { DOMSerializer } from './util/domSerializer'
import { emitOnNth, makeIntervalBufferer } from './util/rxjs'
import type { CodelessEvent } from './events'
import { createCodelessEventsObservable } from './events'
import { createNgineMapper, initNgine, ngineGlobals } from './ngine'

interface InitConfig {
  LOG_ENDPOINT?: string
  disableWatch?: {
    [key in ActionType]?: boolean
  }
  token: string
  local?: boolean
}

const STREAM_NAME = import.meta.env.STREAM_NAME || 'nlytics-devStreamNlytics'
const STREAM_REGION = import.meta.env.STREAM_REGION || 'us-east-1'
const IDENTITY_POOL_ID = import.meta.env.IDENTITY_POOL_ID || 'us-east-1:6f2a6dfc-6514-4a2d-ba8f-00c7a2b02970'
const REST_API_ENDPOINT = import.meta.env.REST_API_ENDPOINT || 'https://s5yj0lez05.execute-api.us-east-1.amazonaws.com/prod/'
const GEOLOCATE_API_ENDPOINT = import.meta.env.GEOLOCATE_API_ENDPOINT || 'https://ip.dev.nlytics.co/prod'

// New session ID every 30 seconds
const SESSION_TIMEOUT_MS = 30_000

// Break up messages to kinesis in 1MB chunks
const MAX_CHUNK_SIZE = 1e6

// Configure amplify
Amplify.configure({
  Auth: {
    Cognito: {
      allowGuestAccess: true,
      identityPoolId: IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    },
  },
  Analytics: {
    Kinesis: {
      region: STREAM_REGION, // https://docs.amplify.aws/lib/analytics/streaming/q/platform/js/#installation-and-configuration
      // bufferSize: 40,
      // flushSize: 10,
      // flushInterval: 10000,
      resendLimit: 5,
    },
  },
})

// This is a utility function to convert a string to a Uint8Array
function str2ab(str: string) {
  const buf = new Uint8Array(str.length) // 2 bytes for each char
  // const bufView = new Uint16Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++)
    buf[i] = str.charCodeAt(i)

  return buf
}

// This is a utility function to convert a string to a Uint8Array
function encodeToUint8Array(str: string) {
  if (typeof TextEncoder === 'function')
    return new TextEncoder().encode(str)

  else
    return str2ab(str)
}

// Utility function to normalize a URL, needs to be the same across lambdas/frontend, etc
function normalizeURL(url: string) {
  const urlObj = new URL(url)
  urlObj.search = ''
  urlObj.hash = ''
  return urlObj.toString()
}

// This is a function that needs an exact mirror on the kinesis side. It serializes events into a smaller structure that works with JSON
function shrink(events: RecordingEventByActionType[]) {
  return events.map(({ type, timestamp, data }) => [type, Math.round(timestamp), data] as const)
}

// Utility function to handle page id parsing
function parsePageId(pageId: string) {
  const [pageStartTimestamp, pageIndex] = pageId.split('#')
  return {
    pageStartTimestamp,
    pageIndex: parseInt(pageIndex),
  }
}

// Utility function to clean up local storage
function pruneLocalStorageKeys(remove: (key: string) => boolean) {
  const keys = Object.keys(localStorage)
  for (const key of keys) {
    if (remove(key))
      localStorage.removeItem(key)
  }
}

// id functions
function makeSessionId(t: number, token: string, lastVisit?: Date) {
  const sessionIdLocalStorageKey = `_n_id_${token}`
  const savedSessionID = localStorage.getItem(sessionIdLocalStorageKey)

  const isNewSession = !savedSessionID || !lastVisit || new Date().valueOf() - lastVisit.valueOf() > SESSION_TIMEOUT_MS

  if (!isNewSession)
    return savedSessionID

  const sessionTimestamp = (new Date(t)).toISOString()
  const sessionUUID = crypto.randomUUID().slice(0, 8).toUpperCase()
  const sessionId = `${sessionTimestamp}#${sessionUUID}`
  // Reset session tracker
  localStorage.setItem(sessionIdLocalStorageKey, sessionId)
  return sessionId
}

function makePageId(t: number, sessionId: string) {
  const pageIdLocalStorageKey = `_n_page_id_${sessionId}`
  const savedPageId = localStorage.getItem(pageIdLocalStorageKey)
  const isNewPage = !savedPageId

  // Each new page should have a new timestamp and increment the previous page in the session
  const pageStartTimestamp = new Date(t).toISOString()
  const pageIndex = isNewPage ? 0 : parsePageId(savedPageId).pageIndex + 1

  const pageId = `${pageStartTimestamp}#${pageIndex}`
  // Persist it!
  localStorage.setItem(pageIdLocalStorageKey, pageId)
  return pageId
}

function makeVisitorID() {
  let visitorId = document.cookie.split('; ').find(row => row.startsWith('ngine-visitor-id'))?.split('=')[1]
  if (!visitorId) {
    visitorId = crypto.randomUUID()
    document.cookie = `ngine-visitor-id=${visitorId};max-age=31536000`
  }
  return visitorId
}

function getIDs(config: Partial<InitConfig>, lastVisit?: Date) {
  if (!config.token)
    throw new Error('You must provide a token to init()')

  const startTimestamp = Date.now()
  const sessionId = makeSessionId(startTimestamp, config.token, lastVisit)
  const pageId = makePageId(startTimestamp, sessionId)
  const visitorId = makeVisitorID()
  return { sessionId, pageId, startTimestamp, visitorId }
}

// Utility function to record last visit
function recordLastVisit(token: string) {
  localStorage.setItem(`_n_last_visit_${token}`, (new Date()).toISOString())
}

// Utility function to expose keys to global so that typescript doesn't complain each time
function expose(key: string, value: any) {
  // @ts-expect-error - this is fine
  window[key] = value
}

// Event queuing functions
// The event queue is a simple object that stores events in session storage
// Every new event is pushed into the queue from the observable
// Whenever an event successfully pushes, it is removed from the queue
// This way, we can ensure that events are not lost if the user navigates away
function getPendingEventPushes(): Record<string, { params: { pageUrl: string; sessionId: string; visitorId: string }; events: (CodelessEvent & { chosenTests?: { ngineSk: string; chosenTest: string }[] })[] }> {
  return JSON.parse(sessionStorage.getItem('_n_pendingEventPushes') || '{}')
}

function addPendingEventPush(id: string, value: { params: { pageUrl: string; sessionId: string; visitorId: string }; events: (CodelessEvent & { chosenTests?: { ngineSk: string; chosenTest: string }[] })[] }) {
  sessionStorage.setItem('_n_pendingEventPushes', JSON.stringify({ [id]: value, ...getPendingEventPushes() }))
}

function removePendingEventPush(id: string) {
  sessionStorage.setItem('_n_pendingEventPushes', JSON.stringify({ ...getPendingEventPushes(), [id]: undefined })) // simple shorthand to remove a key immutably
}

// The main body of the ntag
export async function init(window: Window, config: Readonly<Partial<InitConfig>> = {}) {
  // Always require a token
  if (!config.token)
    throw new Error('You must provide a token to init()')

  // It's important that we get ids before recoridng the last visit
  const savedLastVisit = localStorage.getItem(`_n_last_visit_${config.token}`)
  const lastVisit: Date | undefined = savedLastVisit ? new Date(savedLastVisit) : undefined
  const { sessionId, pageId, startTimestamp, visitorId } = getIDs(config, lastVisit)
  pruneLocalStorageKeys(key => (key.startsWith('_n_id_') && key.replace('_n_id_', '') !== config.token)
                                || (key.startsWith('_n_last_visit') && key.replace('_n_last_visit', '') !== config.token)
                                || (key.startsWith('_n_page_id_') && key.replace('_n_page_id_', '') !== sessionId))
  recordLastVisit(config.token)

  // Expose some globals to the window so integrations and debuggers can see them
  expose('_ntag_token', config.token) // expose token to integrations
  expose('_ntag_session_id', sessionId)
  expose('_ntag_page_id', pageId)
  expose('_ntag_active_ngines', ngineGlobals.activeNgines)

  // Get the JWT token
  // Note that this is not awaited so we can immediately move on to recording without the promise delaying everything
  const tokenPromise = fetch(new URL('public/token', REST_API_ENDPOINT), {
    method: 'POST',
    body: JSON.stringify({
      token: config.token,
    }),
  }).then(res => res.json()).then(res => res.jwt)
  let globalJWTToken: string | null = null
  tokenPromise.then((token) => {
    localStorage.setItem('_n_token', token)
    globalJWTToken = token
  })

  // Push all the events that are pending in the queue
  Object.entries(getPendingEventPushes()).forEach(([pendingPushId, value]) => {
    pushCodelessEventsRaw(value.params, value.events, pendingPushId)
  })

  // Create the serializer, which carries some mutable state also unfortunately
  // This creates a condensed object representation of the entire DOM tree
  const domSerializer = new DOMSerializer()

  // The order key ensures that events from the same session are received in order on the Kinesis side
  // even if they are sent in multiple packets
  let lastOrderKey = 0
  function getLastOrderKey() {
    return lastOrderKey++
  }

  // This is a thin wrapepr around amplify kinesis that includes the token
  async function recordWithToken(orderKey: number, data: any) {
    const token = await tokenPromise
    return record({
      data: encodeToUint8Array(`${token},${sessionId},${pageId},${orderKey}::${data}`),
      partitionKey: `${config.token}#${sessionId}`, // interesting thing here bc we don't include session uuid (we're 2 chars over, we MUST rely on firehose...)
      streamName: STREAM_NAME,
    })
  }

  // This checks whether the token is expired which is useful for pushing events
  function tokenIsExpiredNoValidate() {
    const token = globalJWTToken
    if (!token)
      return false

    const { exp } = JSON.parse(atob(token.split('.')[1]))
    return exp < Date.now() / 1000
  }

  // This is the main way that recording (replayer) events are pushed to kinesis
  function pushPayloadToKinesis(payload: any) {
    const jsonified = JSON.stringify(payload)
    console.debug('Pushing payload to kinesis', payload, jsonified.length) // eslint-disable-line no-console

    // Break it up into chunks if it is too big
    if (jsonified.length > MAX_CHUNK_SIZE) {
      const nChunks = Math.ceil(jsonified.length / MAX_CHUNK_SIZE)
      console.debug('Payload will be chunked, too big', `${jsonified.length} > ${MAX_CHUNK_SIZE}`, `nChunks=${nChunks}`) // eslint-disable-line no-console
      const recordPromises = []
      for (let i = 0; i < jsonified.length; i += MAX_CHUNK_SIZE) {
        const orderKey = getLastOrderKey()
        const chunk = jsonified.slice(i, i + MAX_CHUNK_SIZE)
        recordPromises.push(recordWithToken(orderKey, chunk).then(results => console.debug('Got results from chunked push', results))) // eslint-disable-line no-console
      }
      Promise.all(recordPromises).then(flushEvents)
    }
    // Otherwise, just push it
    else {
      const orderKey = getLastOrderKey()
      recordWithToken(orderKey, jsonified).then(flushEvents)
    }
  }

  // This is a (probably unnecessary) wrapper around the previous function
  // that is more specific in the types it accepts
  function pushRecordingEvents(events: RecordingEventByActionType[]) { // dummy function for now
    if (events.length === 0)
      return

    if (tokenIsExpiredNoValidate())
      return console.error('Token is expired, not pushing to kinesis')
    pushPayloadToKinesis(shrink(events))
  }

  // This function is different from the above in that it pushes codeless events
  // and it pushes them to api gateway instead of kinesis
  // This is used when pushing codeless events from the observable and from the queue
  function pushCodelessEventsRaw(params: { pageUrl: string; sessionId: string; visitorId: string }, events: (CodelessEvent & { chosenTests?: { ngineSk: string; chosenTest: string }[] })[], pendingPushId = `${config.token}#${crypto.randomUUID()}`) {
    // console.log('Pushing codeless events', events) // TODO nice lil debug message to be removed from prod
    if (events.length === 0)
      return
    addPendingEventPush(pendingPushId, { params, events })
    fetch(new URL(`public/events/${config.token}?${new URLSearchParams(params)}`, REST_API_ENDPOINT), {
      method: 'POST',
      body: JSON.stringify(events),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      // keepalive: true,
    }).then(() => {
      removePendingEventPush(pendingPushId)
    })
  }

  // Another stupid wrapper that is used in one other place
  function pushCodelessEvents(events: (CodelessEvent & { chosenTests?: { ngineSk: string; chosenTest: string }[] })[]) {
    pushCodelessEventsRaw({ pageUrl: location.href, sessionId, visitorId }, events)
  }

  // This function pushes the entire setup event to the server
  // The setup event contains all session metadata and the entire DOM and styles
  // which are all essential for replaying and also for analytics
  // We push setup to api gateway instead of kinesis because it is too big
  function pushSetup(setup: RecordingSetup) {
    tokenPromise.then((token) => {
      if (tokenIsExpiredNoValidate())
        return console.error('Token is expired, not pushing to kinesis')
      fetch(new URL(`public/setup?${new URLSearchParams({
        token,
        sessionId,
        pageId: pageId.toString(),
      })}`, REST_API_ENDPOINT), {
        method: 'POST',
        body: JSON.stringify(setup),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        // keepalive: true,
      })
    })
  }

  // Ping this before the page loads
  // This is used to get the geolocation of the user
  // We have several fallbacks that we chain in here just in case our endpoint fails
  const ping = fetch(GEOLOCATE_API_ENDPOINT).then(x => x.json()).catch(() => fetch('https://ipapi.co/json/').then(x => x.json()).catch(() => fetch('https://www.cloudflare.com/cdn-cgi/trace').then(x => x.text()).then(x => Object.fromEntries(x.split('\n').map(x => x.split('='))))).catch(() => null))

  // This promise fetches metrics (i.e. codeless events and ngines)
  // Also not awaited!
  const metricsPromise = fetch(new URL(`public/events/${config.token}/metrics?${new URLSearchParams({ pageUrl: normalizeURL(location.href), sessionId, visitorId })}`, REST_API_ENDPOINT)).then(x => x.json()).then(x => x.data).catch(e => console.error(e, 'Ntag metrics fetch failed'))

  // And then we finally do things when the page loads
  function handleLoad() {
    // Start the recording and send state setup (ooh alliteration) to the thingum (and by thingum, I mean apigw)
    recordingSetup(window, window.document, domSerializer, startTimestamp, ping).then((setup) => {
      pushSetup(setup)
      // set global state
      if (config.local)
        expose('_nlytics_setup', setup)
    })

    // This is an observable, the first one in the code
    // It is intimidating
    // In simple terms, it records everything that happens on the page
    // The createEverythingRecorderObservable function is a big function that does a lot of things
    // It gathers all events from various listeners and wrangles them into a unified format
    // that is easy to serialize and pass on to kinesis
    // Observable variables end with $ as a convention
    const recorder$ = createEverythingRecorderObservable(window, window.document, domSerializer, startTimestamp, config)

    // Take all the session ending events
    const beforeUnload$ = fromEvent(window, 'beforeunload')
    const visibilityChange$ = fromEvent(window.document, 'visibilitychange').pipe(filter(() => document.visibilityState === 'hidden'))
    const blur$ = fromEvent(window, 'blur')
    const sessionEnding$ = merge(beforeUnload$, visibilityChange$, blur$)
    // this "merge" observable combines the three events and emits when any of them fire

    // Emit the first four events in the first buffer
    // This ensures that we get something from every recording sooner instead of waiting for buffering to complete
    const startBuffering$ = recorder$.pipe(emitOnNth(4))

    // Emit buffer every time it looks like the session is ending
    const restartBufferInterval$ = merge(startBuffering$, sessionEnding$)

    // Link up the bufferer to the recorder
    // Buffer every 5 seconds (maybe this should be a constant!)
    recorder$.pipe(makeIntervalBufferer(restartBufferInterval$, 5_000)).subscribe(pushRecordingEvents)

    // Friendly little log message that we should probably remove eventually
    startBuffering$.subscribe(() => {
      // console.log('Buffering started')
    })

    // Record last visit every 10 seconds
    // Remember that this doesn't have any server calls; it just saves to local storage
    // so that we can track when the user last visited and make breaks in sessions when
    // it has been a while
    if (config.token)
      recorder$.pipe(throttleTime(10_000)).subscribe(recordLastVisit.bind(null, config.token))

    // Set up the global state
    if (config.local) {
      recorder$.subscribe((x) => {
        // I leave these here not expose()'d because it just makes more sense to read
        // as an array vs a function call
        // and the push makes sense
        // @ts-expect-error - this is fine
        window._nlytics_events = window._nlytics_events || []
        // @ts-expect-error - this is fine
        window._nlytics_events.push(x)
        // memory leak? yes. remove in prod or something.
      })
    }

    // Set up the ngine and codeless events
    metricsPromise.then(({ eventListeners, ngines }: { eventListeners?: any; ngines?: any[] } = {}) => {
      // init the ngine, modify page with edits from specified option that the server provides
      if (ngines)
        initNgine(window, sessionId, ngines)

      // Make observables for the event listeners
      // console.log('Event listeners', eventListeners) // TODO nice lil debug message to be removed from prod
      if (eventListeners) {
        // Just like the recorder, this is a big function that does a lot of things
        const eventsObservable$ = createCodelessEventsObservable(eventListeners, sessionId)
        // console.log('Events observable again main', eventsObservable$) // TODO nice lil debug message to be removed from prod

        // Just like the recorder, we stitch together observables to make sure we don't lose events
        // and have some nice buffering/throttling to smooth over network requests
        const eventsNgineObservable$ = eventsObservable$.pipe(createNgineMapper(window, sessionId))
        // console.log('Events ngine observable', eventsNgineObservable$) // TODO nice lil debug message to be removed from prod
        const startBuffering$ = eventsNgineObservable$.pipe(emitOnNth(1))
        const restartBufferInterval$ = merge(startBuffering$, sessionEnding$)

        const bufferedEventsNgineObservable$ = eventsNgineObservable$.pipe(makeIntervalBufferer(restartBufferInterval$, 5_000))
        bufferedEventsNgineObservable$.subscribe(pushCodelessEvents)
      }
    })

    // This is an async import because we want it in a separate bundle
    // Synaps is huge and we can wait to collect fingerprints
    import('./synaps').then(async ({ synaps }) => {
      const synapsResults = await synaps() // this creates the fingerprint results (note that it's still inaccurate because we lack the infrastructure (e.g. workers!))
      // console.log('Synaps results', sessionId, synapsResults) // nice lil debug message again to be removed from prod
      // Save synaps results to our api gateway
      fetch(new URL('public/synaps', REST_API_ENDPOINT), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          // Yes there are three parts, and we on't know what they all do
          // We are selecting these specific fields and removing some because they are too big/the dynamodb serializer borks on some of them
          synaps: ({
            fp: ({ ...synapsResults?.fp, offlineAudioContext: null, maths: (synapsResults?.fp.maths) }),
            creep: ({ ...synapsResults?.creep, offlineAudioContext: (synapsResults?.creep.offlineAudioContext) }),
            fpHash: synapsResults?.fpHash,
          }),
          visitorId,
          pageId,
          sessionId,
          token: await tokenPromise,
        }),
      })
    })
  }

  // Finally, we check if the document is already loaded
  // If it is, run our function
  // If it isn't, wait for it to load
  if (document.readyState === 'complete')
    handleLoad()
  else
    window.addEventListener('load', handleLoad)

  // And we're done!
  // This is probably the most useful log message
  // It makes it really easy to see whether ngine has been set up and at least some of it is working
  // If you don't see this message, ngine is for sure not working
  // Check for misconfigurations, check network logs, and check for syntax errors and that kind of thing
  // console.log('💅 Nlytics Tag Initialized') // eslint-disable-line no-console
}
